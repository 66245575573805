import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  zIndex,
  desktopVW,
  desktopBreakpoint,
  desktopVH,
  colors,
  mobileVW,
  mobileVH,
  font,
} from '../styles'
import gsap from 'gsap'

let z = zIndex.high + 1

const Curtain = ({ mode, color, initial }, ref) => {
  const wrapperRef = useRef()
  const contentRef = useRef()

  useImperativeHandle(ref, () => ({
    getShowTimeline: (duration, ease = 'quart.inOut') => {
      if (contentRef.current) {
        contentRef.current.style.zIndex = z++
      }

      const tl = gsap.timeline()
      tl.fromTo(
        wrapperRef.current,
        duration,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          visibility: 'inherit',
          ease: ease,
        },
      )

      if (initial) {
        tl.to(contentRef.current.querySelectorAll('.leave'), duration / 4, {
          autoAlpha: 0,
          ease: 'expo.out',
        })
        tl.to(contentRef.current.querySelectorAll('.leave'), duration / 4, {
          autoAlpha: 0,
          width: 0,
          ease: 'expo.out',
        })
      }
      // switch (!mode) {
      //   case 'inital':
      //     console.log('this')
      //     break

      //   case 'load':
      //     tl.fromTo(
      //       contentRef.current.querySelectorAll('.leave'),
      //       duration * 4,
      //       {
      //         x: index => {
      //           return index ? '-120vw' : '120vw'
      //         },
      //       },
      //       {
      //         x: index => {
      //           return index ? '80vw' : '-80vw'
      //         },
      //         ease: 'expo.out',
      //         stagger: { each: 0.1, from: 'end' },
      //       },
      //       duration * 0.3,
      //     )

      //     tl.fromTo(
      //       contentRef.current.querySelectorAll('.banner'),
      //       duration * 1,
      //       {
      //         x: index => {
      //           return index ? '-100vw' : '100vw'
      //         },
      //       },
      //       {
      //         x: 0,
      //         y: 0,
      //         ease: 'expo.out',
      //         stagger: { each: 0.1, from: 'end' },
      //       },
      //       duration * 0.3,
      //     )

      //     tl.fromTo(
      //       contentRef.current.querySelectorAll('.banner__wrapper'),
      //       duration * 4,
      //       {
      //         x: index => {
      //           return index ? '-120vw' : '120vw'
      //         },
      //       },
      //       {
      //         x: index => {
      //           return index ? '80vw' : '-80vw'
      //         },
      //         ease: 'expo.out',
      //         stagger: { each: 0.1, from: 'end' },
      //       },
      //       duration * 0.3,
      //     )
      //     break
      // }

      return tl
    },
    getHideTimeline: (duration, ease = 'expo.out') => {
      const tl = gsap.timeline()
      tl.to(wrapperRef.current, duration, {
        // y: '-100%',
        autoAlpha: 0,
        ease: ease,
        onComplete: () => {
          wrapperRef.current.style.visibility = 'hidden'
        },
      })

      return tl
    },
  }))

  return (
    <CurtainWrapper ref={wrapperRef} color={color}>
      <>
        <Loader ref={contentRef}>
          <Title>
            {/* <>
              <p>A</p>
              <div className='leave'>
                sk <Space />
              </div>
              <p>P</p>
              <div className='leave'>
                hill <Space />
              </div>
              V<div className='leave'>entures</div>
            </p> */}
          </Title>
        </Loader>
      </>
    </CurtainWrapper>
  )
}

export default forwardRef(Curtain)

const CurtainWrapper = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.high + 1};
  background: black;
  visibility: hidden;
`
const China = styled.div``

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Title = styled.div`
  display: flex;
  margin: ${desktopVW(50)} ${desktopVW(50)} ${desktopVW(50)};
  font-size: ${desktopVW(30)};
  font-weight: 800;
  font-family: ${font.extended};
  text-transform: uppercase;
  align-items: center;

  .leave {
    display: flex;
  }
`

const Space = styled.div`
  display: block;
  height: ${desktopVW(20)};
  width: ${desktopVW(20)};
`
