import { atom } from 'recoil'

export const inTransition = atom({
  key: 'inTransition',
  default: true,
})

export const startPageAnimation = atom({
  key: 'startPageAnimation',
  default: false,
})
