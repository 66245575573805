import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import Transition from 'react-transition-group/Transition'
// import { useDispatch, useSelector } from 'react-redux'
// import { setTransitionState, setDisableTransition } from 'actions'
import SmoothScroll from './SmoothScroll'
import Curtain from './Curtain'

import gsap from 'gsap'
import detectIt from 'detect-it'

import { useRecoilState } from 'recoil'
import { inTransition, startPageAnimation } from '../atoms'

gsap.config({ force3D: true })

const PageTransition = ({ children, pageId, scrollEnabled }) => {
  const [, setIsTransitioning] = useRecoilState(inTransition)
  const [, setStartPage] = useRecoilState(startPageAnimation)
  const [count, setCount] = useState(0)

  const smoothScrollRef = useRef()
  const transitionWrapperRef = useRef()
  const curtainRef = useRef()
  // console.log('hello pages', pageId)
  // const dispatch = useDispatch()

  const transitionTypes = ['first', 'notfirrst']

  const [transitionType, setTransitionType] = useState('first')
  // const transitionDisabled = useSelector(
  //   ({ transition }) => transition.disabled,
  // )

  const transitionDisabled = false

  const transitionDuration = 1

  const translate = 8

  useEffect(() => {
    setIsTransitioning(true)
    setStartPage(false)
    const i = transitionTypes.indexOf(transitionType)

    setTransitionType(i !== 0 ? 'notfirst' : 'first')

    if (transitionDisabled) {
      transitionWrapperRef.current.style.visibility = 'inherit'
      // dispatch(setDisableTransition(false))
    }
  }, [pageId])

  const onExit = node => {
    // dispatch(setTransitionState('exit'))
    const jsTransition = node.querySelector('.js-transition')
    gsap.to(
      jsTransition,
      transitionDuration,
      {
        y: `-${translate}vh`,
        ease: 'power2.inOut',
        onComplete: () => {
          node.ended()
        },
      },
      0,
    )
  }

  const onEntering = (node, isAppearing) => {
    if (count === 1) {
      const d = transitionDuration / 1
      gsap
        .timeline()
        .add(curtainRef.current.getShowTimeline(d, 'expo.inOut'), 0)
        .add(curtainRef.current.getHideTimeline(d, 'expo.inOut'), d)
        .to(
          transitionWrapperRef.current,
          {
            keyframes: [
              { y: '100vh', ease: 'power2.inOut', duration: 0 },
              {
                y: `${translate}vh`,
                ease: 'power2.inOut',
                duration: 0,
                delay: d,
                onStart: () => {
                  setIsTransitioning(true)
                  //dispatch(setTransitionState('entering'))
                },
              },
              {
                y: '0',
                ease: 'power2.inOut',
                duration: d,
                clearProps: 'transform',
              },
            ],
            onStart: () => {
              transitionWrapperRef.current.style.visibility = 'inherit'
              setTimeout(() => {
                setStartPage(true)
              }, 200)
            },
            onComplete: () => {
              node.ended()
              setIsTransitioning(false)

              //dispatch(setTransitionState('entered'))
            },
          },
          0,
        )
    } else {
      // from a page to another
      gsap
        .timeline()
        .add(
          curtainRef.current.getShowTimeline(transitionDuration, 'expo.inOut'),
          0,
        )
        .add(
          curtainRef.current.getHideTimeline(transitionDuration, 'expo.inOut'),
          transitionDuration,
        )
        .fromTo(
          transitionWrapperRef.current,
          transitionDuration,
          { y: `${translate}vh` },
          {
            visibility: 'inherit',
            y: '0%',
            ease: 'power2.inOut',
            clearProps: 'transform',
            onStart: () => {
              //dispatch(setTransitionState('entering'))
              setTimeout(() => {
                setStartPage(true)
              }, 200)
              window.scrollTo(0, 0)
              smoothScrollRef.current.reset()
            },
            onComplete: () => {
              node.ended()
              setIsTransitioning(false)
              //dispatch(setTransitionState('entered'))
            },
          },
          transitionDuration,
        )
    }
  }

  const onEndListener = (node, done) => {
    node.ended = () => {
      done()
    }
  }

  return (
    <>
      <TransitionGroup>
        <Transition
          key={pageId}
          enter={!transitionDisabled}
          exit={!transitionDisabled}
          addEndListener={onEndListener}
          onEntering={onEntering}
          onExit={onExit}
          appear
        >
          {status => (
            <>
              <SmoothScroll
                ref={smoothScrollRef}
                enabled={
                  (status === 'entering' ||
                    status === 'entered' ||
                    detectIt.primaryInput === 'touch') &&
                  scrollEnabled
                }
              >
                <TransitionWrapper
                  ref={transitionWrapperRef}
                  className='js-transition'
                >
                  {children}
                </TransitionWrapper>
              </SmoothScroll>
              <Curtain
                ref={curtainRef}
                mode={transitionType}
                initial={count === 0}
              />
            </>
          )}
        </Transition>
      </TransitionGroup>
    </>
  )
}

const TransitionWrapper = styled.div`
  visibility: hidden;
`

export default PageTransition
