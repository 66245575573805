import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  desktopVW,
  font,
  colors,
  quad,
  expo,
  mobileBreakpoint,
  desktopBreakpoint,
  mobileVW,
  quart,
  zIndex,
} from '../styles'
import { inTransition } from '../atoms'
import { useRecoilValue } from 'recoil'

const StyledHeader = styled.section`
  margin: ${desktopVW(50)} ${desktopVW(50)} ${desktopVW(50)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: ${zIndex.high};
  justify-content: space-between;
  font-size: ${desktopVW(10)};
  font-weight: 800;
  font-family: ${font.extended};
  text-transform: uppercase;
  align-items: center;
  ${mobileBreakpoint} {
    justify-content: center;
  }
`
const Logo = styled(Link)`
  font-size: ${desktopVW(12)};
  letter-spacing: 0.02em;
  color: ${props => (props.active ? '#8b8b8b' : 'white')};
  transition: all 0.2s ease-in;
  display: flex;

  span {
    overflow: hidden;
  }
  p {
    visibility: visible;
    width: 0;
    opacity: 0;
    transform: translateX(200%);
    transition: width 0.6s ${expo.inOuts};
  }

  /* &:hover {
    .ask {
      width: ${desktopVW(50)};
    }
    .phill {
      width: ${desktopVW(70)};
    }

    .ventures {
      width: ${desktopVW(150)};
    }

    p {
      visibility: visible;
      transform: translateX(0%);
      opacity: 1;
      transition: width 0.6s ${expo.inOuts};
    }
  } */

  ${mobileBreakpoint} {
    font-size: ${mobileVW(16)};
  }
`
const Menu = styled.div`
  ${mobileBreakpoint} {
    display: none;
  }
`
const MenuItem = styled(Link)`
  color: white;
  ${desktopBreakpoint} {
    margin-left: ${desktopVW(20)};
    position: relative;
    display: inline-block;
    color: ${colors.grey};
    &::after {
      content: '';
      display: block;
      margin-top: 2px;
      height: 1px;
      background: ${colors.white};
      transform: scaleX(0);
      transition: none;
      transform-origin: left;
    }
    &:hover {
      color: ${colors.white};
    }
    &:hover::after {
      transform: scaleX(1);
      transition: transform 0.3s ${quad.inOut};
    }
  }
`

const MobileMenu = styled.div`
  position: fixed;
  bottom: ${mobileVW(30)};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: ${zIndex.high};
  pointer-events: none;

  ${desktopBreakpoint} {
    display: none;
  }
`
const MobileMenuExpanded = styled.div`
  position: fixed;
  background-color: black;
  color: black;
  opacity: 0;
  display: flex;
  align-items: center;
  font-size: ${mobileVW(12)};
  flex-direction: column;
  justify-content: space-evenly;
  padding: ${mobileVW(30)} 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: ${mobileVW(100)};
  height: ${mobileVW(230)};
  width: ${mobileVW(300)};
  transform: translate3d(0px, 0px, 0px);
  transition: all 0.3s ${quart.inOut};
  pointer-events: none;
  &.active {
    opacity: 1;
    pointer-events: auto;
    transform: translate3d(0px, ${mobileVW(-10)}, 0px);
  }
`
const MobileMenuButton = styled.button`
  font-family: ${font.extended};
  background-color: white;
  width: 29vw;
  height: 13vw;
  border-radius: 20vw;
  font-size: ${mobileVW(9)};
  pointer-events: auto;
  color: black;
`

const PageOverlay = styled.div`
  position: fixed;
  background-color: #4f4f4f;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.high - 1};
  pointer-events: none;
  transition: all 0.3s ${quart.inOut};
  &.active {
    pointer-events: auto;
    opacity: 0.6;
  }
`

const Header = () => {
  const transitioning = useRecoilValue(inTransition)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen)
    console.log(isMenuOpen)
  }
  return (
    <StyledHeader>
      <Logo to='/' active={transitioning}>
        A
        <span>
          <p className='ask'>SK </p>
        </span>
        P
        <span>
          <p className='phill'>HILL </p>
        </span>
        V
        <span>
          <p className='ventures'>ENTURES </p>
        </span>
      </Logo>
      <Menu>
        <MenuItem to='/story'>OUR STORY</MenuItem>
        <MenuItem to='/news'>NEWSROOM</MenuItem>
        <MenuItem to='/contact'>Contact</MenuItem>
      </Menu>
      <MobileMenu>
        <MobileMenuExpanded className={isMenuOpen ? 'active' : ''}>
          <MenuItem onClick={() => handleMenuOpen()} to='/'>
            HOME
          </MenuItem>
          <MenuItem onClick={() => handleMenuOpen()} to='/story'>
            OUR STORY
          </MenuItem>
          <MenuItem onClick={() => handleMenuOpen()} to='/news'>
            NEWSROOM
          </MenuItem>
          <MenuItem onClick={() => handleMenuOpen()} to='/contact'>
            Contact
          </MenuItem>
        </MobileMenuExpanded>
        <MobileMenuButton onClick={() => handleMenuOpen()}>
          {isMenuOpen ? 'CLOSE' : 'MENU'}
        </MobileMenuButton>
      </MobileMenu>
      <PageOverlay
        onClick={() => handleMenuOpen()}
        className={isMenuOpen ? 'active' : ''}
      />
    </StyledHeader>
  )
}

export default Header
