import React from 'react'

import Layout from './src/components/Layout'
import { RecoilRoot } from 'recoil'

export const wrapPageElement = ({ element }) => (
  <Layout>
    {console.log('element in browser', element)}
    {element}
  </Layout>
)

export const wrapRootElement = ({ element }) => (
  <RecoilRoot>{element}</RecoilRoot>
)
