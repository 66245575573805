import React from 'react'
import styled from 'styled-components'
import { mobileVW, desktopVW, desktopBreakpoint } from '../styles'

const StyledFooter = styled.section`
  display: flex;
  justify-content: center;
  margin: ${mobileVW(70)} ${mobileVW(12)} ${mobileVW(100)};
  font-size: ${mobileVW(12)};

  ${desktopBreakpoint} {
    margin: ${desktopVW(160)} ${desktopVW(50)} ${desktopVW(50)};
    font-size: ${desktopVW(14)};
  }
`
const Legal = styled.div``
const Login = styled.a``

const Footer = () => (
  <StyledFooter>
    <Legal>Ask Phill Ventures B.V. 2020</Legal>
  </StyledFooter>
)

export default Footer
