import React, { useEffect, useState } from 'react'
import { createGlobalStyle } from 'styled-components'
import { Normalize } from 'styled-normalize'
import CanelaWoff from '../fonts/Canela-Light-Web.woff'
import CanelaWoff2 from '../fonts/Canela-Light-Web.woff2'
import TitWoff from '../fonts/TitlingGothicFBExtended-Regular.woff'
import TitWoff2 from '../fonts/TitlingGothicFBExtended-Regular.woff2'
import Header from './Header'
import Footer from './Footer'
import PageTransition from './PageTransition'
import useWindowResize from '../hooks/window/useWindowResize'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Canela';
    src:
      url('${CanelaWoff2}') format('woff2'),
      url('${CanelaWoff}') format('woff');
    font-weight: 300;
    font-style: light;
  }
  @font-face {
    font-family: 'Extended';
    src:
      url('${TitWoff2}') format('woff2'),
      url('${TitWoff}') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  * {
    box-sizing: border-box;
  }
  body {
    margin: 0 auto;
    background: #000000;
    color: #F0F0EC;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family:  'Canela', sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  h1, h2 ,h3 {
    font-size: inherit;
    margin: 0;
    font-weight: inherit;
  }
  p {
    margin: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  button {
    border: none;
    padding: 0;
    outline: none;
    background: none;
    cursor: pointer;
  }
`

const Layout = ({ children }) => {
  if (typeof window !== 'undefined' && !Window.innerHeight) {
    Window.innerWidth = window.innerWidth
    Window.innerHeight = window.innerHeight
  }
  useWindowResize(e => {
    Window.innerWidth = e.innerWidth
    Window.innerHeight = e.innerHeight
  })

  return (
    <div>
      <Normalize />
      <GlobalStyle />
      <Header />
      <PageTransition
        pageId={
          children && children[1] && children[1].props && children[1].props.path
        }
      >
        <main>{children}</main>
        <Footer />
      </PageTransition>
    </div>
  )
}

export default Layout
