export const desktopBreakpoint = `@media (min-width: 1024px)`

export const mobileBreakpoint = `@media (max-width: 1023px)`

export const mobileVW = px => {
  return `${(px / 375) * 100}vw`
}

export const desktopVW = px => {
  return `${(px / 1440) * 100}vw`
}

export const letterSpacing = space => {
  return space => `${(space / 1000) * 1}em`
}

export const quad = {
  in: 'cubic-bezier( 0.26, 0, 0.6, 0.2 )',
  out: ' cubic-bezier( 0.4, 0.8, 0.74, 1 )',
  inOut: 'cubic-bezier( 0.48, 0.04, 0.52, 0.96 )',
}

export const cubic = {
  in: 'cubic-bezier( 0.4, 0, 0.68, 0.06 )',
  out: 'cubic-bezier( 0.34, 1.02, 0.68, 1 )',
  inOut: 'cubic-bezier( 0.66, 0, 0.34, 1 )',
}

export const expo = {
  in: 'cubic-bezier( 0.66, 0, 0.86, 0 )',
  out: 'cubic-bezier( 0.16, 1.08, 0.38, 0.98 )',
  inOut: 'cubic-bezier( 0.9, 0, 0.1, 1 )',
}

export const quart = {
  in: 'cubic-bezier( 0.52, 0, 0.74, 0 )',
  out: 'cubic-bezier( 0.26, 1.04, 0.54, 1 )',
  inOut: 'cubic-bezier( 0.770, 0.000, 0.175, 1.000 )',
}

export const back = {
  in: 'cubic-bezier( 0.600, -0.280, 0.735, 0.045 )',
  out: 'cubic-bezier( 0.175, 0.885, 0.320, 1.275 )',
  inOut: 'cubic-bezier( 0.175, 0.885, 0.320, 1.275 )',
}

export const font = {
  serif: 'Canela',
  extended: 'Extended',
}

export const colors = {
  orange: '#FF7002',
  purple: '#C8D5F0',
  grey: '#63666a',
  white: '#F0F0EC',
  black: '#000',
  backgroundImage: 'red',
}

export const mobileVH = px => {
  return `${((px / 812) * 100).toFixed(2)}vh`
}

export const desktopVH = px => {
  return `${((px / 900) * 100).toFixed(2)}vh`
}

export const zIndex = {
  low: 1,
  medium: 10,
  high: 10000,
}
